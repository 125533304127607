import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"
import PageTemplate from '../components/pageTemplate'

import styled from "@emotion/styled"
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { MdPlayCircleOutline } from 'react-icons/md';
import Img from "gatsby-image"
import iconPlay from "../assets/play.svg"

class AboutPage extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      modalState: 'closed',
    };
  }

  toggleModal = (event) => {
    this.setState({
      modalState: this.state.modalState === 'open' ? 'closed' : 'open'
    });
  }

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale;

    const title = "Theme"
    const post = data.wordpressPage
    const hasData = post.acf && post.acf.page_about__flexcontent_page;

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>

        {hasData &&
          post.acf.page_about__flexcontent_page.map((layout, i) => {
            if (layout.__typename === `WordPressAcf_layout_video`) {
              return (
                this.layoutVideo(layout, i)
              )
            }
            return null
          })}

        <Crate className="bg-white">
          <MainContainer className="px-5 sm:px-0 py-3 text-gray-900">

            {hasData &&
              post.acf.page_about__flexcontent_page.map((layout, i) => {

                if (layout.__typename === `WordPressAcf_layout_content`) {
                  return (
                    this.layoutContent(layout, i)
                  )
                }
                if (layout.__typename === `WordPressAcf_layout_content_alt`) {
                  return (
                    this.layoutContentAlt(layout, i)
                  )
                }
                if (layout.__typename === `WordPressAcf_layout_card_row`) {
                  return (
                    this.layoutCardRow(layout, i)
                  )
                }
                if (layout.__typename === `WordPressAcf_layout_card_group`) {
                  return (
                    this.layoutCardGroup(layout, i)
                  )
                }

                return null
              })}

          </MainContainer>
        </Crate>
      </PageTemplate>

    )
  }

  layoutVideo(layout, key) {

    const Overlay = styled.div`
      position: fixed;
      z-index: 999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(33,33,33, 0.8);
    `

    const VideoWrapper = styled.div`
      position: relative;
      background-color: #262262;
      :hover{
        background-color: #363272;
      }
      .cta{
        font-size: 1.6rem;
        svg{
          font-size: 4.8rem;
        }
      }
      :hover .cta{
        transform: scale(1.1);
      }
    `

    const Span = styled.span`
      width: 25px;
      height: 2px;
      display: inline-block;
      font-size: 0;
    `

    const modalState = this.state.modalState

    return (
      <Crate key={`about-layout-video-${key}`} className="jja-about-wrapper-layout-video">
        <MainContainer>
          <section className="about-layout-video">

            {(modalState === 'open') &&
              <Overlay>
                <div className="mx-auto bg-blue-900 rounded max-w-xl relative p-3 mt-20">
                  <h3 className="tk-harfang-pro text-white cyan-900 text-mmd my-3">
                    <FormattedMessage id="Welcome to JESEDU-Global2021" />
                  </h3>
                  <span className="absolute top-0 right-0 pt-2 px-2">
                    <IoIosCloseCircleOutline onClick={this.toggleModal} className='text-white cyan-900 cursor-pointer' style={{ fontSize: `2.5rem` }} />
                  </span>
                  <iframe onClick={this.toggleModal} title="About Video" class="rounded" width="640" height="360" style={{ maxWidth: `100%` }}
                    src={`https://www.youtube.com/embed/${layout.video_id}?modestbranding=1&autoplay=0&showinfo=0&controls=0&autohide=1`}>
                  </iframe>
                </div>
              </Overlay>
            }

            <div className="flex flex-wrap">
              <VideoWrapper className="flex jja-transition w-full sm:w-1/2 cursor-pointer" onClick={this.toggleModal}>
                <div className="flex flex-col justify-center items-center mx-auto my-10 jja-transition cta">
                  <div className="my-2">
                    <img style={{ width: `64px` }} src={iconPlay} alt="Play" />
                  </div>
                  <div className="font-semibold my-2 uppercase text-cyan-500">
                    <FormattedMessage id="Play Video" />
                  </div>
                </div>
              </VideoWrapper>
              <div className="flex items-center w-full sm:w-1/2 bg-cyan-900 p-5 md:px-16 md:py-10 tk-harfang-pro font-medium text-mmd text-white">
                <div dangerouslySetInnerHTML={{ __html: layout.content_right }}></div>
              </div>
            </div>
            <div className="bg-blue-900 p-5 md:px-16 md:py-10 lg:px-24 text-olg text-white" dangerouslySetInnerHTML={{ __html: layout.content_bottom }}></div>
          </section >
        </MainContainer >
      </Crate >
    )
  }

  layoutContent(layout, key) {

    return (
      <section id={`about-layout-content-${key}`} key={`about-layout-content-${key}`} className="about-layout-content mt-20 mb-20">
        <div className="w-full sm:w-5/6 mx-auto" dangerouslySetInnerHTML={{ __html: layout.content }}></div>
      </section>
    )
  }

  layoutContentAlt(layout, key) {

    return (
      <section id={`about-layout-content-${key}`} key={`about-layout-content-${key}`} className="about-layout-content mt-10 mb-10">
        <div className="w-full sm:w-5/6 mx-auto">
          <div className="flex flex-wrap-reverse -mx-10">
            <div className="w-full sm:w-2/3 px-10 my-10" dangerouslySetInnerHTML={{ __html: layout.content }}></div>
            <div className="w-full sm:w-1/3 px-10 my-10">
              <Img style={{ width: `100%`, maxWidth: `280px` }} fluid={layout.image_url.localFile.childImageSharp.fluid} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
  }

  layoutCardRow(layout, key) {

    const theme = (layout.theme === 'theme_a') ? " text-blue-900 bg-cyan-100" : " text-blue-900 bg-blue-100"
    const base = "p-5 md:px-16 lg:px-24 my-5 rounded-lg "

    return (
      <section id={`about-layout-card-row-${key}`} key={`about-layout-card-row-${key}`} className="about-layout-card-row my-10">
        <div className="w-full sm:w-5/6 mx-auto">
          <div className={base + theme} dangerouslySetInnerHTML={{ __html: layout.content }}></div>
        </div>
      </section>
    )
  }

  layoutCardGroup(layout, key) {
    return (
      <section id={`about-layout-card-group-${key}`} key={`about-layout-card-group-${key}`} className="about-layout-card-group my-20">
        <div className="container mx-auto bg-white">
          <div className="flex -mx-2 flex-wrap">
            <div className="w-full px-2 my-5">
              <div className="bg-cyan-900 h-full overflow-hidden md:flex">
                <Img className="md:w-1/2" fluid={layout.card_left.image_url.localFile.childImageSharp.fluid} alt="" />
                <div className="md:w-1/2 p-3 sm:p-10" dangerouslySetInnerHTML={{ __html: layout.card_left.content }}>
                </div>
              </div>
            </div>
            <div className="w-full px-2 my-5 md:w-1/2">
              <div className="bg-purple-900 h-full overflow-hidden">
                <Img fluid={layout.card_center.image_url.localFile.childImageSharp.fluid} alt="" />
                <div className="p-3 sm:p-10" dangerouslySetInnerHTML={{ __html: layout.card_center.content }}></div>
              </div>
            </div>
            <div className="w-full px-2 my-5 md:w-1/2">
              <div className="bg-blue-900 h-full overflow-hidden">
                <Img fluid={layout.card_right.image_url.localFile.childImageSharp.fluid} alt="" />
                <div className="p-3 sm:p-10" dangerouslySetInnerHTML={{ __html: layout.card_right.content }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default AboutPage

export const query = graphql`
  query($pId: Int!, $pType: String!) {

    wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
      acf {
        page_about__flexcontent_page {
          ... on WordPressAcf_layout_video {
            __typename
            content_bottom
            content_right
            video_id
          }
          ... on WordPressAcf_layout_content {
            __typename
            content
          }
          ... on WordPressAcf_layout_content_alt {
            __typename
            content
            image_url{
              localFile {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }              
            }
          }          
          ... on WordPressAcf_layout_card_row {
            __typename
            content
            theme
          }
          ... on WordPressAcf_layout_card_group {
            __typename
            card_left {
              content
              image_url{
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            card_center {
              content
              image_url{
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            card_right {
              content
              image_url{
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    headerImage: file(relativePath: { eq: "jesedu-global-icon-white.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`